import React, { useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';
import { kindOfContracts, splitRows, splitColumn, removeDuplicates, parseDate, getDaysArray } from '../helpers.js';
import { Calendar } from 'primereact/calendar/calendar.esm.js';
import { DataTable } from 'primereact/datatable/datatable.esm.js';
import { Column } from 'primereact/column/column.esm.js';
import { InputText } from 'primereact/inputtext/inputtext.esm.js';
import { FilterMatchMode, FilterOperator } from 'primereact/api/api.esm.js';
import { Button } from 'primereact/button/button.esm.js';
import { Dropdown } from 'primereact/dropdown/dropdown.esm.js';
import axios, {isCancel, AxiosError} from 'axios';
import Cookies from 'js-cookie';
import Notallowed from '../myaccount/Notallowed.js';
import {isLoggedIn, getPrivacyStandard} from '../helpers.js';
export const MContext = React.createContext();  //exporting context object

// import { classNames } from 'primereact/utils';
let typeOfContracts = kindOfContracts();
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
    domainOrigin = "https://reportingapi.milgenx.com";
}else{
    domainOrigin = "http://localhost:8080";   
}
let userData = isLoggedIn(Cookies);
let privacyControl = getPrivacyStandard(Cookies);

export default function Trackingbyclients(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [countByDate, setCountByDate] = useState("");
    const [rowClick, setRowClick] = useState(true);
    let [listCount, setListCount] = useState(1);
    const [staff, setStaff] = useState(null);
    
    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
            let requestUrI = domainOrigin+"/daily-reporting";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            // After fetching data stored it in posts state.
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setItems(result.reverse());
            }
        }
        getCount()
        // forceUpdate();
    }, [listCount])

    let staffs = [];
    let userDetails = props.userDetails;
    let currentUserName = userDetails.userName;

    // Filter records by user name
    if(items){
        let allStaffs = removeDuplicates(splitColumn(items, "freelancer_name"));
        allStaffs.forEach(function(staff, i){
            staffs.push({staff: staff})
            if (i === allStaffs.length - 1){ 
                staffs.push({staff: "All Staff"})
            }
        })
    }

    let allItems = []
    if(staff){
        let selectedStaff = staff.staff;
        if(selectedStaff === 'All Staff'){
            allItems = items
        }else{
            allItems = splitRows(items, 'freelancer_name',  selectedStaff);
        }
    }else{
        allItems = items;
    }

    console.log(staff, currentUserName, staffs)

    let userRecords = "";
    if(props.viewMode == "only-user" && currentUserName){
        userRecords = splitRows(allItems, "freelancer_name", currentUserName);
    }else if(props.viewMode == "all"){
        userRecords = allItems;
    }

    let allClients = [];
    let recordByClient = [];

    const [dateRange, setdateRange] = useState(null);
    let fromDate = '';
    let endDate = '';
    if(dateRange){
        let unFilteredRecords = userRecords;
        if(dateRange[0]){
            fromDate = parseDate(dateRange[0]);
        }
        if(dateRange[1]){
            endDate = parseDate(dateRange[1]);
        }

        var selectedDays = getDaysArray(new Date(fromDate),new Date(endDate));
        selectedDays.map((v)=>v.toISOString().slice(0,10)).join("");

        let filteredRecords = []
        selectedDays.forEach(function(selectedDay){
            let recordDay = parseDate(selectedDay);
            filteredRecords.push(splitRows(unFilteredRecords, 'date', recordDay));
        })

        if(filteredRecords.length > 0){
            filteredRecords = filteredRecords.flat();
            allClients = splitColumn(filteredRecords, "client_name");
            userRecords = filteredRecords;
        } 
    }else{
        allClients = splitColumn(userRecords, "client_name");
        userRecords = userRecords;
    }

    allClients = removeDuplicates(allClients);
    allClients.forEach(function(client, index){
        let clientRecords = splitRows(userRecords, "client_name", client);
        let clientRecordsByContract, records = [];
        let totalBilledTimeSpents = 0;
        let totalNotBilled = 0;
        let totalNonBilledTimeSpents = 0;
        let totalFixedTimeSpents = 0;
        let totalTimeSpents = 0;

        typeOfContracts.forEach(function(type_of_contract){
            let typeOfContract = type_of_contract.code
            // For total time spents
            clientRecordsByContract = splitRows(clientRecords, "type_of_contract", typeOfContract);
          
            let timeSpents = splitColumn(clientRecordsByContract, "worked_hours");

            // Total time spents calculation
            timeSpents.forEach(function(timeSpent){
                let TotalMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                let workedHours = Number(TotalMinute / 60);
                totalTimeSpents = totalTimeSpents + workedHours;

                // Total Billed Hours Calculation
                if(typeOfContract === 'billable'){
                    let TotalBilledMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                    let BilledWorkedHours = Number(TotalBilledMinute / 60);
                    totalBilledTimeSpents = totalBilledTimeSpents + BilledWorkedHours;
                }

                // Total Not Billed Hours Calculation
                if(typeOfContract === 'not-billed'){
                    let TotalNotBilledMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                    let notBilledWorkedHours = Number(TotalNotBilledMinute / 60);
                    totalNotBilled = notBilledWorkedHours + notBilledWorkedHours;
                }

                // Total Non Billed Hours Calculation
                if(typeOfContract === 'non-billable'){
                    let TotalNonBilledMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                    let NonBilledWorkedHours = Number(TotalNonBilledMinute / 60);
                    totalNonBilledTimeSpents = totalNonBilledTimeSpents + NonBilledWorkedHours;
                }

                // Total Fixed Hours Calculation
                if(typeOfContract === 'fixed-cost'){
                    let TotalFixedBilledMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                    let FixedWorkedHours = Number(TotalFixedBilledMinute / 60);
                    totalFixedTimeSpents = totalFixedTimeSpents + FixedWorkedHours;
                }
            })
            records.push({contractType:typeOfContract, contractRecords: clientRecordsByContract});
        })
        recordByClient.push({clientName: client, clientRecords: clientRecords, clientRecordsByContract: records, "Not Billed": totalNotBilled.toFixed(2), "Billed Hours": totalBilledTimeSpents.toFixed(2), "Non Billed Hours": totalNonBilledTimeSpents.toFixed(2), "Billed Fixed Hours": totalFixedTimeSpents.toFixed(2), "Total Worked Hours": totalTimeSpents.toFixed(2) });
    })

    // console.log({TableName, productHandle});   
    const [ignored, forceUpdate] = useState(listCount);

    const [date, setDate] = useState(null);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        setLoading(true);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
        setLoading(false);
    };
    

    const cols = [
        { field: 'phone', header: 'Phone' },
        { field: 'email', header: 'Email' },
        { field: 'origin', header: 'Origin' },
    ];
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    // const dt = useRef(null);
    // const exportCSV = (selectionOnly) => {
    //     dt.current.exportCSV({ selectionOnly });
    // };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, items);
                doc.save("daily-records"+'.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(items);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, "daily-records");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const [selectedRows, setSelectedRows] = useState([]);
    // console.log(selectedRows);

    const renderHeader = () => {
        let itemsCount = recordByClient.length;
        const changeState = () => {  
            listCount = listCount + 1;
            setListCount(listCount);
            // console.log(".. "+ listCount);
        }
        return (
            <div className="d-flex flex-wrap justify-content-between">
                <div className="heading full-width">
                    <small onClick={changeState}>Showing User Records (Count: {itemsCount})</small>
                </div>
                <div className='d-flex flex-wrap justify-content-between full-width'>
                    <div className="d-flex py-1 align-items-center justify-content-end gap-2"> 
                        <span className="p-input-icon-left">
                            <i className="pi pi-search small" />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                        </span>
                        <Calendar value={dateRange} onChange={(e) => setdateRange(e.value)} selectionMode="range" readOnlyInput showIcon />
                    </div>

                    <div className="d-flex py-1 align-items-center justify-content-end gap-2">
                        {/* <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(true)} data-pr-tooltip="CSV" /> */}
                        {privacyControl == false &&
                            <Dropdown value={staff} defaultValue={currentUserName} onChange={(e) => setStaff(e.value)} options={staffs} optionLabel="staff" 
                            placeholder={currentUserName} className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                        }
                        <Button type="button" label="Refresh" onClick={changeState} />
                        <Button type="button" label="Download in Excel" onClick={exportExcel} data-pr-tooltip="XLS" />
                        {/* <Button type="button" label="Download in PDF" onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                    </div>
                    {/* <Button icon="pi pi-download" /> */}
                </div>
            </div>
        );
    };
    const header = renderHeader();

    
    const dateEditor = (options) => {
        return <Calendar className="" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} name='dateRange' selectionMode="single" dateFormat="d MM yy" placeholder={options.value} showButtonBar />;
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const parsedDate = (rowData) => {
        let date = rowData.date.toLocaleString("en-IN", {timeZone: "Asia/kolkata"});
        // console.log({date})
        return (
            <span>{date}</span>
        );
    };

    let viewBtn = (rowData) => {
        // console.log({rowData});
        return (
            <Link to={'/tracking/detail'} className="p-button p-component" state={rowData}>
                <span className='p-button-label p-c' data-pc-section="label">View Records</span>
                {/* <FetchLeadCount tableName={key['table-name']} origin={key['product-handle']} /> */}
            </Link>
        );
    };
    
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading Clients...</div>;
    } else {
        return (
        <div className="full-width">
                <div className="data-table-preview full-width">
                    <DataTable className="lead-table w-100" value={recordByClient} selectionMode={rowClick ? null : 'checkbox'} selection={selectedRows} onSelectionChange={(e) => setSelectedRows(e.value)} paginator scrollable filters={filters} size="small" header={header} 
                    loading={loading} 
                    scrollHeight="70vh" 
                    rows={30} 
                    editMode="row" 
                    rowsPerPageOptions={[30, 100, 250]} 
                    tableStyle={{ minWidth: '100%' }} 
                    globalFilterFields={['date', 'clientName', 'type_of_contract']}>
                        <Column field="clientName" header="Client Name"></Column>
                        <Column field="Billed Hours" header="Billable Hours"></Column>
                        <Column field="Not Billed" header="Not Billed"></Column>
                        <Column field="Non Billed Hours" header="Non Billable Hours"></Column>
                        <Column field="Billed Fixed Hours" header="Fixed Cost Hours"></Column>
                        <Column field="Total Worked Hours" header="Total Worked Hours"></Column>
                        <Column header="View In Detail" body={viewBtn}></Column>
                    </DataTable>
                </div>
        </div>
        );
    }
}
