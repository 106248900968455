export function domainOriginSource(arr){
    let origin = window.origin;
    let domainOriginSource = "";
    if(origin.includes("reporting.milgenx.com")){
        domainOriginSource = "https://reportingapi.milgenx.com";
    }else{
        domainOriginSource = "http://localhost:8080";   
    }
    return domainOriginSource;
}

export function sumOfColumn(arr){
    let count = 0;
    let countSumInitlizer = 0;
    arr.forEach(function(arrItem){
        count = countSumInitlizer + Number(arrItem);
        countSumInitlizer = count;
    })
    return count;
}

export function getFormattedDate(str) {
    const dateRaw = new Date(str);
    const day = dateRaw.getDate();
    const month = dateRaw.getMonth() + 1; // Months are zero-based, so add 1
    const year = dateRaw.getFullYear();

    return `${month}/${day}/${year}`;
}

export function splitColumn(arr, arraylabel) {
    // console.log(arr, arraylabel);
    let newArray = [];
    if(Array.isArray(arr) && arraylabel){
        arr.forEach(function(arrItem, index){
            let splitColumnItem = arrItem[arraylabel];
            newArray.push(splitColumnItem);
        })
        return newArray;
    }else{
        return "arraylabel to split coulmn not defined.";
    }
}

export function splitRows(arr, matchWith, arraylabel) {
    let newArray = [];
    // console.log({arr, matchWith, arraylabel})

    if(Array.isArray(arr) && arraylabel){
        arr.forEach(function(arrItem, index){
            if(arrItem[matchWith] === arraylabel){
                newArray.push(arrItem);
            }
        })
        return newArray;
    }else{
        return "arraylabel to split rows not defined.";
    }
}

export function removeDuplicates(arr) {
    return [...new Set(arr)];
}

export function sumValues(arr){
    arr.reduce((accumulator, object) => {
    return accumulator + object.salary;
    }, 0);
}

export function parseDate(date){
    let dateValue = new Date(date);
    if(date){
        let parsedDate = '';
        let dateRaw = dateValue.toLocaleString('en-IN', {timeZone: 'IST'}).split(',')[0].split("/");
        return parsedDate = dateRaw[1]+"/"+dateRaw[0]+"/"+dateRaw[2];
    }else{
        return "";
    }
}

export function extractDate(date){
    if(date){
        return date.split('T')[0];
    }
}

export function getDaysArray(start, end) {
    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
};

export function compareStrings(s1, s2){
   
    // This condition will return true only if s1 and s2 hold true from equality
    if(s1 === s2){
        return true;
    }
    
    return false;
}

export function isLoggedIn(Cookies){
    let userEmail = Cookies.get('userEmail');
    let userName = Cookies.get('userName');
    let loggedIn = Cookies.get('loggedIn');
    let accessAllowed = Cookies.get('access_allowed');
    let userRole = Cookies.get('user_role');
    let userDepartment = Cookies.get('userDepartment');
    let userData = {loggedIn: loggedIn, userEmail: userEmail, userName: userName, accessAllowed: accessAllowed, userRole: userRole, userDepartment: userDepartment}
    return userData;
} 

export function calcTime(timeSpents){
    let totalTimeSpents = 0;
    if(Array.isArray(timeSpents)){
        timeSpents.forEach(function(timeSpent){
            // console.log({timeSpent})
            let TotalMinute = (timeSpent) ? Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]) : 0;
            let workedHours = Number(TotalMinute / 60);
            totalTimeSpents = totalTimeSpents + workedHours;
            // console.log({timeSpent, totalTimeSpents, workedHours, TotalMinute})
        })
    }
    return totalTimeSpents.toFixed(2);
}

export function getPrivacyStandard(Cookies){
    let userRole = Cookies.get('user_role');
    console.log({userRole})
    let privacyControl = true;
    if(userRole === "founder" || userRole === "co-founder"){
        privacyControl = false;
    }else {
        privacyControl = true;
    }
    return privacyControl;
}
// https://medium.com/@atif.ali.ati/user-session-in-react-js-aa749bc4faf6#:~:text=We%20can%20initiate%20our%20session,WEBSQL%2C%20LOCALSTORAGE%20or%20COOKIES%20like%E2%80%A6

// export function userProfile(prop){
//     var userName = "";
    
//     var getUserEmail = Cookies.get('userEmail');
//     var getUserName = Cookies.get('userName');
//     var loggedIn  = () => {
//         return Cookies.get('loggedIn');    // Or pull this from cookie/localStorage
//     };
    
//     var setUserName  = (name) => {
//         console.log("Hello")
//         console.log({name})
//         setUserName = name;
//         Cookies.set('userName',setUserName);
//         // Also set this in cookie/localStorage
//     };

//     var setUserEmail  = (email) => {
//         setUserEmail = email;
//         Cookies.set('userEmail',setUserEmail);
//         // Also set this in cookie/localStorage
//     };
    
//     return {
//         props.getUserEmail = getUserEmail;
//         getUserName: getUserName,
//         loggedIn: loggedIn
//     }
// }
// export default userProfile;

export function billingMethods(){
    let billingMethods = [{name: "Billing By Upwork", code: "By upwork"}, {name: "Billing By Invoice", code: "by-invoice"}];
    return billingMethods;
}

export function clientSource(){
    let clientSource = [{name: "Upwork", code: "upwork"}, {name: "Direct", code: "direct"}];
    return clientSource;
}

export function kindOfContracts(){
    let typeOfContracts = [{name: "Billed by Hours", code: "billable"}, {name: "Billable by Invoice", code: "billable-by-invoice"}, {name: "Non Billable Hours", code: "non-billable"}, {name: "Not Billed", code: "not-billed"}, {name: "Fixed Cost Project", code: "fixed-cost"}];
    return typeOfContracts;
}

export function typeOfProjects(){
    let typeOfProjects = [{name: "Billed by Hours", code: "billable"}, {name: "Fixed Cost Project", code: "fixed-cost"},{name: "Billable by Invoice", code: "billable-by-invoice"}];
    return typeOfProjects;
}

export function statusOfProjects(){
    let statusOfProjects = [{name: "In Discussion", code: "in-discussion"}, {name: "In Progress", code: "in-progress"}, {name: "In Review", code: "in-review"}, {name: "Payment Pending", code: "payment-pending"}, {name: "Canceled", code: "canceled"}, {name: "Completed", code: "completed"}];
    return statusOfProjects;
}